import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Heading1, Heading2, Heading3, Heading4, Heading5, Heading6, Paragraph, StrongText, SmallText, MicroText } from '@sb1/ffe-core-react';
export const _frontmatter = {
  "title": "Typografi",
  "order": 3,
  "template": "default",
  "introtext": "SpareBank 1 har sin helt egen skrifttype som skal bygge personlighet og gjøre at vi skiller oss ut i markedet"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Skrifttyper`}</h2>
    <p>{`SpareBank 1-skrifttypene har et sirkulært formspråk for å knytte identiteten sammen og snakke med en gjenkjennelig stemme.`}</p>
    <h3>{`Store overskrifter`}</h3>
    <p>{`SpareBank 1 Title brukes i overskrifter med stor størrelse (overskriftsnivå 1 og 2). Title har ekstra mye særpreg med tydelig sirkulære former som står i kontrast til de smalere bokstavene.`}</p>
    <h3>{`Mindre overskrifter og brødtekst`}</h3>
    <p>{`I tillegg til SpareBank 1 Title finnes det en komplementær tekstfont til bruk i overskrifter med mindre størrelse (overskriftsnivå 3 og mindre), og til små og lengre tekster. Denne er lettere å lese og har mindre av de sirkulære formene som finnes i SpareBank 1 Title-fonten. `}</p>
    <h2>{`Desktop`}</h2>

    <dl className="sb1ds-typography-hierarchy sb1ds-typography-hierarchy--desktop">
    <div className="sb1ds-typography-hierarchy__row">
        <dt className="sb1ds-typography-hierarchy__description">Heading1</dt>
        <dd className="sb1ds-typography-hierarchy__element">
            <Heading1 className="sb1ds-typography-hierarchy__example sb1ds-typography-hierarchy__example--h1" role="presentation" mdxType="Heading1">
                SpareBank 1 Title Medium
            </Heading1>
        </dd>
        <dd className="sb1ds-typography-hierarchy__font-size">46 px</dd>
    </div>
    <div className="sb1ds-typography-hierarchy__row">
        <dt className="sb1ds-typography-hierarchy__description">Heading2</dt>
        <dd className="sb1ds-typography-hierarchy__element">
            <Heading2 className="sb1ds-typography-hierarchy__example sb1ds-typography-hierarchy__example--h2" role="presentation" mdxType="Heading2">
                SpareBank 1 Title Medium
            </Heading2>
        </dd>
        <dd className="sb1ds-typography-hierarchy__font-size">36 px</dd>
    </div>
    <div className="sb1ds-typography-hierarchy__row">
        <dt className="sb1ds-typography-hierarchy__description">Heading3</dt>
        <dd className="sb1ds-typography-hierarchy__element">
            <Heading3 className="sb1ds-typography-hierarchy__example sb1ds-typography-hierarchy__example--h3" role="presentation" mdxType="Heading3">
                SpareBank 1 Title Medium
            </Heading3>
        </dd>
        <dd className="sb1ds-typography-hierarchy__font-size">28 px</dd>
    </div>
    <div className="sb1ds-typography-hierarchy__row">
        <dt className="sb1ds-typography-hierarchy__description">Heading4</dt>
        <dd className="sb1ds-typography-hierarchy__element">
            <Heading4 className="sb1ds-typography-hierarchy__example sb1ds-typography-hierarchy__example--h4" role="presentation" mdxType="Heading4">
                SpareBank 1 Title Medium
            </Heading4>
        </dd>
        <dd className="sb1ds-typography-hierarchy__font-size">22 px</dd>
    </div>
    <div className="sb1ds-typography-hierarchy__row">
        <dt className="sb1ds-typography-hierarchy__description">Heading5</dt>
        <dd className="sb1ds-typography-hierarchy__element">
            <Heading5 className="sb1ds-typography-hierarchy__example sb1ds-typography-hierarchy__example--h5" role="presentation" mdxType="Heading5">
                SpareBank 1 Title Medium
            </Heading5>
        </dd>
        <dd className="sb1ds-typography-hierarchy__font-size">18 px</dd>
    </div>
    <div className="sb1ds-typography-hierarchy__row">
        <dt className="sb1ds-typography-hierarchy__description">Heading6</dt>
        <dd className="sb1ds-typography-hierarchy__element">
            <Heading6 className="sb1ds-typography-hierarchy__example sb1ds-typography-hierarchy__example--h6" role="presentation" mdxType="Heading6">
                SpareBank 1 Title Medium
            </Heading6>
        </dd>
        <dd className="sb1ds-typography-hierarchy__font-size">16 px</dd>
    </div>
    <div className="sb1ds-typography-hierarchy__row">
        <dt className="sb1ds-typography-hierarchy__description">Lead</dt>
        <dd className="sb1ds-typography-hierarchy__element">
            <Paragraph lead={true} className="sb1ds-typography-hierarchy__example sb1ds-typography-hierarchy__example--lead-paragraph" mdxType="Paragraph">
                SpareBank 1 Regular
            </Paragraph>
        </dd>
        <dd className="sb1ds-typography-hierarchy__font-size">24 px</dd>
    </div>
    <div className="sb1ds-typography-hierarchy__row">
        <dt className="sb1ds-typography-hierarchy__description">SubLead</dt>
        <dd className="sb1ds-typography-hierarchy__element">
            <Paragraph subLead={true} className="sb1ds-typography-hierarchy__example sb1ds-typography-hierarchy__example--sub-lead-paragraph" mdxType="Paragraph">
                SpareBank 1 Regular
            </Paragraph>
        </dd>
        <dd className="sb1ds-typography-hierarchy__font-size">18 px</dd>
    </div>
    <div className="sb1ds-typography-hierarchy__row">
        <dt className="sb1ds-typography-hierarchy__description">BodyText</dt>
        <dd className="sb1ds-typography-hierarchy__element">
            <Paragraph className="sb1ds-typography-hierarchy__example sb1ds-typography-hierarchy__example--body-text" mdxType="Paragraph">
                SpareBank 1 Regular
            </Paragraph>
        </dd>
        <dd className="sb1ds-typography-hierarchy__font-size">16 px</dd>
    </div>
    <div className="sb1ds-typography-hierarchy__row">
        <dt className="sb1ds-typography-hierarchy__description">StrongText</dt>
        <dd className="sb1ds-typography-hierarchy__element">
            <StrongText className="sb1ds-typography-hierarchy__example sb1ds-typography-hierarchy__example--strong-text" mdxType="StrongText">
                SpareBank 1 Medium
            </StrongText>
        </dd>
        <dd className="sb1ds-typography-hierarchy__font-size">16 px</dd>
    </div>
    <div className="sb1ds-typography-hierarchy__row">
        <dt className="sb1ds-typography-hierarchy__description">SmallText</dt>
        <dd className="sb1ds-typography-hierarchy__element">
            <SmallText className="sb1ds-typography-hierarchy__example sb1ds-typography-hierarchy__example--small-text" mdxType="SmallText">
                SpareBank 1 Regular
            </SmallText>
        </dd>
        <dd className="sb1ds-typography-hierarchy__font-size">14 px</dd>
    </div>
    <div className="sb1ds-typography-hierarchy__row">
        <dt className="sb1ds-typography-hierarchy__description">MicroText</dt>
        <dd className="sb1ds-typography-hierarchy__element">
            <MicroText className="sb1ds-typography-hierarchy__example sb1ds-typography-hierarchy__example--micro-text" mdxType="MicroText">
                SpareBank 1 Regular
            </MicroText>
        </dd>
        <dd className="sb1ds-typography-hierarchy__font-size">12 px</dd>
    </div>
    <div className="sb1ds-typography-hierarchy__row">
        <dt className="sb1ds-typography-hierarchy__description">MicroText Medium</dt>
        <dd className="sb1ds-typography-hierarchy__element">
            <MicroText strong={true} className="sb1ds-typography-hierarchy__example sb1ds-typography-hierarchy__example--micro-text" mdxType="MicroText">
                SpareBank 1 Medium
            </MicroText>
        </dd>
        <dd className="sb1ds-typography-hierarchy__font-size">12 px</dd>
    </div>
    </dl>
    <h2>{`Tablet`}</h2>
    <dl className="sb1ds-typography-hierarchy sb1ds-typography-hierarchy--tablet">
    <div className="sb1ds-typography-hierarchy__row">
        <dt className="sb1ds-typography-hierarchy__description">Heading1</dt>
        <dd className="sb1ds-typography-hierarchy__element">
            <Heading1 className="sb1ds-typography-hierarchy__example sb1ds-typography-hierarchy__example--h1" role="presentation" mdxType="Heading1">
                SpareBank 1 Title Medium
            </Heading1>
        </dd>
        <dd className="sb1ds-typography-hierarchy__font-size">40 px</dd>
    </div>
    <div className="sb1ds-typography-hierarchy__row">
        <dt className="sb1ds-typography-hierarchy__description">Heading2</dt>
        <dd className="sb1ds-typography-hierarchy__element">
            <Heading2 className="sb1ds-typography-hierarchy__example sb1ds-typography-hierarchy__example--h2" role="presentation" mdxType="Heading2">
                SpareBank 1 Title Medium
            </Heading2>
        </dd>
        <dd className="sb1ds-typography-hierarchy__font-size">32 px</dd>
    </div>
    <div className="sb1ds-typography-hierarchy__row">
        <dt className="sb1ds-typography-hierarchy__description">Heading3</dt>
        <dd className="sb1ds-typography-hierarchy__element">
            <Heading3 className="sb1ds-typography-hierarchy__example sb1ds-typography-hierarchy__example--h3" role="presentation" mdxType="Heading3">
                SpareBank 1 Title Medium
            </Heading3>
        </dd>
        <dd className="sb1ds-typography-hierarchy__font-size">27 px</dd>
    </div>
    <div className="sb1ds-typography-hierarchy__row">
        <dt className="sb1ds-typography-hierarchy__description">Heading4</dt>
        <dd className="sb1ds-typography-hierarchy__element">
            <Heading4 className="sb1ds-typography-hierarchy__example sb1ds-typography-hierarchy__example--h4" role="presentation" mdxType="Heading4">
                SpareBank 1 Title Medium
            </Heading4>
        </dd>
        <dd className="sb1ds-typography-hierarchy__font-size">22 px</dd>
    </div>
    <div className="sb1ds-typography-hierarchy__row">
        <dt className="sb1ds-typography-hierarchy__description">Heading5</dt>
        <dd className="sb1ds-typography-hierarchy__element">
            <Heading5 className="sb1ds-typography-hierarchy__example sb1ds-typography-hierarchy__example--h5" role="presentation" mdxType="Heading5">
                SpareBank 1 Title Medium
            </Heading5>
        </dd>
        <dd className="sb1ds-typography-hierarchy__font-size">18 px</dd>
    </div>
    <div className="sb1ds-typography-hierarchy__row">
        <dt className="sb1ds-typography-hierarchy__description">Heading6</dt>
        <dd className="sb1ds-typography-hierarchy__element">
            <Heading6 className="sb1ds-typography-hierarchy__example sb1ds-typography-hierarchy__example--h6" role="presentation" mdxType="Heading6">
                SpareBank 1 Title Medium
            </Heading6>
        </dd>
        <dd className="sb1ds-typography-hierarchy__font-size">16 px</dd>
    </div>
    <div className="sb1ds-typography-hierarchy__row">
        <dt className="sb1ds-typography-hierarchy__description">Lead</dt>
        <dd className="sb1ds-typography-hierarchy__element">
            <Paragraph lead={true} className="sb1ds-typography-hierarchy__example sb1ds-typography-hierarchy__example--lead-paragraph" mdxType="Paragraph">
                SpareBank 1 Regular
            </Paragraph>
        </dd>
        <dd className="sb1ds-typography-hierarchy__font-size">24 px</dd>
    </div>
    <div className="sb1ds-typography-hierarchy__row">
        <dt className="sb1ds-typography-hierarchy__description">SubLead</dt>
        <dd className="sb1ds-typography-hierarchy__element">
            <Paragraph subLead={true} className="sb1ds-typography-hierarchy__example sb1ds-typography-hierarchy__example--sub-lead-paragraph" mdxType="Paragraph">
                SpareBank 1 Regular
            </Paragraph>
        </dd>
        <dd className="sb1ds-typography-hierarchy__font-size">18 px</dd>
    </div>
    <div className="sb1ds-typography-hierarchy__row">
        <dt className="sb1ds-typography-hierarchy__description">BodyText</dt>
        <dd className="sb1ds-typography-hierarchy__element">
            <Paragraph className="sb1ds-typography-hierarchy__example sb1ds-typography-hierarchy__example--body-text" mdxType="Paragraph">
                SpareBank 1 Regular
            </Paragraph>
        </dd>
        <dd className="sb1ds-typography-hierarchy__font-size">16 px</dd>
    </div>
    <div className="sb1ds-typography-hierarchy__row">
        <dt className="sb1ds-typography-hierarchy__description">StrongText</dt>
        <dd className="sb1ds-typography-hierarchy__element">
            <StrongText className="sb1ds-typography-hierarchy__example sb1ds-typography-hierarchy__example--strong-text" mdxType="StrongText">
                SpareBank 1 Medium
            </StrongText>
        </dd>
        <dd className="sb1ds-typography-hierarchy__font-size">16 px</dd>
    </div>
    <div className="sb1ds-typography-hierarchy__row">
        <dt className="sb1ds-typography-hierarchy__description">SmallText</dt>
        <dd className="sb1ds-typography-hierarchy__element">
            <SmallText className="sb1ds-typography-hierarchy__example sb1ds-typography-hierarchy__example--small-text" mdxType="SmallText">
                SpareBank 1 Regular
            </SmallText>
        </dd>
        <dd className="sb1ds-typography-hierarchy__font-size">14 px</dd>
    </div>
    <div className="sb1ds-typography-hierarchy__row">
        <dt className="sb1ds-typography-hierarchy__description">MicroText</dt>
        <dd className="sb1ds-typography-hierarchy__element">
            <MicroText className="sb1ds-typography-hierarchy__example sb1ds-typography-hierarchy__example--micro-text" mdxType="MicroText">
                SpareBank 1 Regular
            </MicroText>
        </dd>
        <dd className="sb1ds-typography-hierarchy__font-size">12 px</dd>
    </div>
    <div className="sb1ds-typography-hierarchy__row">
        <dt className="sb1ds-typography-hierarchy__description">MicroText Medium</dt>
        <dd className="sb1ds-typography-hierarchy__element">
            <MicroText strong={true} className="sb1ds-typography-hierarchy__example sb1ds-typography-hierarchy__example--micro-text" mdxType="MicroText">
                SpareBank 1 Medium
            </MicroText>
        </dd>
        <dd className="sb1ds-typography-hierarchy__font-size">12 px</dd>
    </div>
    </dl>
    <h2>{`Mobil`}</h2>
    <dl className="sb1ds-typography-hierarchy sb1ds-typography-hierarchy--mobile">
    <div className="sb1ds-typography-hierarchy__row">
        <dt className="sb1ds-typography-hierarchy__description">Heading1</dt>
        <dd className="sb1ds-typography-hierarchy__element">
            <Heading1 className="sb1ds-typography-hierarchy__example sb1ds-typography-hierarchy__example--h1" role="presentation" mdxType="Heading1">
                SpareBank 1 Title Medium
            </Heading1>
        </dd>
        <dd className="sb1ds-typography-hierarchy__font-size">28 px</dd>
    </div>
    <div className="sb1ds-typography-hierarchy__row">
        <dt className="sb1ds-typography-hierarchy__description">Heading2</dt>
        <dd className="sb1ds-typography-hierarchy__element">
            <Heading2 className="sb1ds-typography-hierarchy__example sb1ds-typography-hierarchy__example--h2" role="presentation" mdxType="Heading2">
                SpareBank 1 Title Medium
            </Heading2>
        </dd>
        <dd className="sb1ds-typography-hierarchy__font-size">24 px</dd>
    </div>
    <div className="sb1ds-typography-hierarchy__row">
        <dt className="sb1ds-typography-hierarchy__description">Heading3</dt>
        <dd className="sb1ds-typography-hierarchy__element">
            <Heading3 className="sb1ds-typography-hierarchy__example sb1ds-typography-hierarchy__example--h3" role="presentation" mdxType="Heading3">
                SpareBank 1 Title Medium
            </Heading3>
        </dd>
        <dd className="sb1ds-typography-hierarchy__font-size">20 px</dd>
    </div>
    <div className="sb1ds-typography-hierarchy__row">
        <dt className="sb1ds-typography-hierarchy__description">Heading4</dt>
        <dd className="sb1ds-typography-hierarchy__element">
            <Heading4 className="sb1ds-typography-hierarchy__example sb1ds-typography-hierarchy__example--h4" role="presentation" mdxType="Heading4">
                SpareBank 1 Title Medium
            </Heading4>
        </dd>
        <dd className="sb1ds-typography-hierarchy__font-size">18 px</dd>
    </div>
    <div className="sb1ds-typography-hierarchy__row">
        <dt className="sb1ds-typography-hierarchy__description">Heading5</dt>
        <dd className="sb1ds-typography-hierarchy__element">
            <Heading5 className="sb1ds-typography-hierarchy__example sb1ds-typography-hierarchy__example--h5" role="presentation" mdxType="Heading5">
                SpareBank 1 Title Medium
            </Heading5>
        </dd>
        <dd className="sb1ds-typography-hierarchy__font-size">17 px</dd>
    </div>
    <div className="sb1ds-typography-hierarchy__row">
        <dt className="sb1ds-typography-hierarchy__description">Heading6</dt>
        <dd className="sb1ds-typography-hierarchy__element">
            <Heading6 className="sb1ds-typography-hierarchy__example sb1ds-typography-hierarchy__example--h6" role="presentation" mdxType="Heading6">
                SpareBank 1 Title Medium
            </Heading6>
        </dd>
        <dd className="sb1ds-typography-hierarchy__font-size">16 px</dd>
    </div>
    <div className="sb1ds-typography-hierarchy__row">
        <dt className="sb1ds-typography-hierarchy__description">Lead</dt>
        <dd className="sb1ds-typography-hierarchy__element">
            <Paragraph lead={true} className="sb1ds-typography-hierarchy__example sb1ds-typography-hierarchy__example--lead-paragraph" mdxType="Paragraph">
                SpareBank 1 Regular
            </Paragraph>
        </dd>
        <dd className="sb1ds-typography-hierarchy__font-size">18 px</dd>
    </div>
    <div className="sb1ds-typography-hierarchy__row">
        <dt className="sb1ds-typography-hierarchy__description">SubLead</dt>
        <dd className="sb1ds-typography-hierarchy__element">
            <Paragraph subLead={true} className="sb1ds-typography-hierarchy__example sb1ds-typography-hierarchy__example--sub-lead-paragraph" mdxType="Paragraph">
                SpareBank 1 Regular
            </Paragraph>
        </dd>
        <dd className="sb1ds-typography-hierarchy__font-size">17 px</dd>
    </div>
    <div className="sb1ds-typography-hierarchy__row">
        <dt className="sb1ds-typography-hierarchy__description">BodyText</dt>
        <dd className="sb1ds-typography-hierarchy__element">
            <Paragraph className="sb1ds-typography-hierarchy__example sb1ds-typography-hierarchy__example--body-text" mdxType="Paragraph">
                SpareBank 1 Regular
            </Paragraph>
        </dd>
        <dd className="sb1ds-typography-hierarchy__font-size">16 px</dd>
    </div>
    <div className="sb1ds-typography-hierarchy__row">
        <dt className="sb1ds-typography-hierarchy__description">StrongText</dt>
        <dd className="sb1ds-typography-hierarchy__element">
            <StrongText className="sb1ds-typography-hierarchy__example sb1ds-typography-hierarchy__example--strong-text" mdxType="StrongText">
                SpareBank 1 Medium
            </StrongText>
        </dd>
        <dd className="sb1ds-typography-hierarchy__font-size">16 px</dd>
    </div>
    <div className="sb1ds-typography-hierarchy__row">
        <dt className="sb1ds-typography-hierarchy__description">SmallText</dt>
        <dd className="sb1ds-typography-hierarchy__element">
            <SmallText className="sb1ds-typography-hierarchy__example sb1ds-typography-hierarchy__example--small-text" mdxType="SmallText">
                SpareBank 1 Regular
            </SmallText>
        </dd>
        <dd className="sb1ds-typography-hierarchy__font-size">14 px</dd>
    </div>
    <div className="sb1ds-typography-hierarchy__row">
        <dt className="sb1ds-typography-hierarchy__description">MicroText</dt>
        <dd className="sb1ds-typography-hierarchy__element">
            <MicroText className="sb1ds-typography-hierarchy__example sb1ds-typography-hierarchy__example--micro-text" mdxType="MicroText">
                SpareBank 1 Regular
            </MicroText>
        </dd>
        <dd className="sb1ds-typography-hierarchy__font-size">12 px</dd>
    </div>
    <div className="sb1ds-typography-hierarchy__row">
        <dt className="sb1ds-typography-hierarchy__description">MicroText Medium</dt>
        <dd className="sb1ds-typography-hierarchy__element">
            <MicroText strong={true} className="sb1ds-typography-hierarchy__example sb1ds-typography-hierarchy__example--micro-text" mdxType="MicroText">
                SpareBank 1 Medium
            </MicroText>
        </dd>
        <dd className="sb1ds-typography-hierarchy__font-size">12 px</dd>
    </div>
    </dl>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      